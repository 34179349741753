const faithcontents = {
    pageTitle: "生活信念",
    pageContent: [
    {
        id: "1sasafhursdc",
        AccordionSummary: "人其实是个复杂的虫子",
        AccordionDetails: `人与虫子一样，在自己不能控制的的因素作用下，产生了生命
        这个生命有生和死两个状态
        死的状态完全是另外一种形态
        生的状态就是不断熵增的过程，不管做什么，其实都是等价的，从宏观角度看，没有意义  
        **生的状态会有两种不同的动力驱动自己的行为**
        - 生存本能：与虫子一致，本能对外界做出反应  
        - 情绪与激素驱动的   
        - 观念：被社会灌输的观念:
        a.需要与生存无关的东西
        b.很多很多“应该”：当中很多“应该”本就不存在，一些需要在特定情况才存在
        c.好坏、高低、美丑、厉害垃圾       
        `
    },
    {
        id: "2sasafhurewdsdc",
        AccordionSummary: "需要怎么去改变",
        AccordionDetails: `**(1)回归本能**
        将维持生存的六好做好
        现在的人类连基本的睡眠都被电子产品干扰的无法很好的去做  
        **(2)敬畏环境**
        人都是根据自己的理解，选择对自己有利的方向决策，然后执念一定要出现自己想要的结果  
        但是环境的复杂程度远超人类的智慧，自己决策完，不必执念一定要出现自己的想要的结果，而是接受多种可能性，并为接受多种可能性做准备   
        **理解但不接受：** 
        破环境（破命）
        烂人
        低效  
        **(3)减法**
        虫子不需要那么复杂，围绕生存最必要的因素，做减法
        （1）处理的事情尽可能简单，
        （2）消耗的钱尽可能少
        （3）涉及的东西尽可能少
        （4）零杠杆零负债为目标  
        **(4)不做**
        - 做完会后悔   游戏
        - 被控制的  短视频，游戏
        - 伤害自己的  
        **(5)去做**
        - 做好能做的
        - 提升
        - 思考真相，记录感悟
        - 扫除整理
   `
    },
]}

export default faithcontents;